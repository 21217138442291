<template>
  <div class="container">
    <el-dialog title="卡密列表"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="70%">
      <table-page dataName="keys"
                  :search="search"
                  :tabList="tabList"
                  ref="table"
                  init
                  :params="paramsObj"
                  :request="getCardKeyList">
        <el-table-column prop="no"
                         show-overflow-tooltip
                         label="卡密">
        </el-table-column>
        <el-table-column prop="status"
                         show-overflow-tooltip
                         label="领取状态">
        </el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer">

      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { getCardKeyList } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      getCardKeyList,
      dialogVisible: false,
      paramsObj: {

      },
      search: [{
        type: 'input',
        placeholder: '请输入卡密id',
        key: 'no',
        value: ''
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NORMAL',
        label: '已领取'
      }, {
        value: 'PENDING',
        label: '未领取'
      }]
    }
  },
  components: {
    TablePage
  },

  methods: {
    showTable (id) {
      this.paramsObj = {
        card_id: id
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
