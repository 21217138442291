<template>
  <div class="container">
    <table-page dataName="cards"
                :search="search"
                ref="table"
                :request="getCardTrickList">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="setCard()">新建卡密</el-button>
      </template>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="id">
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="卡密logo">
        <template slot-scope="scope">
          <TableImg :url="scope.row.logo_img_data ? scope.row.logo_img_data.url :''"></TableImg>
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="卡名称">
      </el-table-column>
      <el-table-column prop="platform_name"
                       show-overflow-tooltip
                       label="平台名称">
      </el-table-column>
      <el-table-column label="库存"
                       prop="total_inventory"
                       show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="user_total_receive"
                       show-overflow-tooltip
                       label="总数量">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="操作"
                       width="260px">
        <template slot-scope="scope">
          <FileUpload :params="{import_type:'card_keys_data',carry_data:{card_id:scope.row.id}}"
                      class="mr-15">导入卡密</FileUpload>
          <el-button type="text"
                     @click="showTable(scope.row)">查看卡密</el-button>
          <el-button type="text"
                     @click="setCard(scope.row)">编辑</el-button>
          <el-button type="text"
                     @click="delCardKey(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetCardKey ref="setCardKey"
                @on-success="$refs.table.fetch()" />
    <Keys ref="keys" />
  </div>
</template>

<script>
import FileUpload from '@/components/FileUploadAsync'
import Keys from './components/Keys'
import TablePage from '@/components/TablePage'
import TableImg from '@/components/TableImg'
import { getCardTrickList, delCardKey } from '@/api/market'
import SetCardKey from './components/SetCardKey'
export default {
  name: 'CardKey',
  data () {
    return {
      getCardTrickList,
      inputList: [{
        name: '卡密id',
        placeholder: '请输入卡密id',
        key: 'id',
        value: ''
      }],
      search: [{
        type: 'input',
        key: 'id',
        value: '',
        name: '卡密id',
        placeholder: '请输入卡密id'
      }]
    }
  },
  components: {
    TablePage,
    SetCardKey,
    FileUpload,
    TableImg,
    Keys
  },
  methods: {
    showTable (row) {
      this.$refs.keys.showTable(row.id)
    },

    setCard (form) {
      this.$refs.setCardKey.showForm(form)
    },
    handleCommand (command, scope) {
      switch (command) {
        case 'employees':
          this.$router.push({ name: 'MemberLuckyEmployees', query: { id: scope.row.id } })
          break
        case 'edit':
          this.$router.push({ name: 'SetMemberLucky', query: { id: scope.row.id } })
          break
        case 'del':
          this.delCardKey(scope.row.id)
          break
        default:
          break
      }
    },
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    delCardKey (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delCardKey({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
