import { render, staticRenderFns } from "./SetCardKey.vue?vue&type=template&id=703071a6&scoped=true&"
import script from "./SetCardKey.vue?vue&type=script&lang=js&"
export * from "./SetCardKey.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703071a6",
  null
  
)

export default component.exports