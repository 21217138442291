<template>
  <div class="">
    <el-dialog :visible.sync="dialogVisible"
               :close-on-click-modal='false'
               title="卡密设置"
               width="50%">
      <el-form ref="form"
               :rules="rules"
               label-position="top"
               :model="form">
        <el-form-item label="卡名称"
                      prop="name">
          <el-input v-model="form.name"
                    placeholder="请输入卡名称"></el-input>
        </el-form-item>
        <el-form-item label="使用时间"
                      prop="started_at">
          <el-date-picker v-model="dateTime"
                          @change="dateChange"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          :default-time="['00:00:00', '23:59:59']"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="卡密logo"
                      prop="logo_img_id">
          <ImgUpload :fileList.sync="imgList2"
                     pixel="用于页面配图，建议使用120*120像素"
                     v-model="form.logo_img_id" />
        </el-form-item>
        <el-form-item label="卡密详情图"
                      prop="img_id">
          <ImgUpload :fileList.sync="imgList"
                     pixel="用于页面配图，建议使用640*640像素"
                     v-model="form.img_id" />
        </el-form-item>
        <el-form-item label="平台名称"
                      prop="platform_name">
          <el-input v-model="form.platform_name"
                    placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item label="副标题"
                      prop="subtitle">
          <el-input v-model="form.subtitle"
                    placeholder="请输入副标题"></el-input>
        </el-form-item>
        <el-form-item label="使用说明"
                      prop="desc">
          <Editor v-model="form.desc"></Editor>
        </el-form-item>
        <el-form-item label="跳转方式"
                      key="jump_type"
                      required
                      prop="jump_type">
          <el-radio-group v-model="form.jump_type">
            <el-radio label="NOT">无</el-radio>
            <el-radio label="PROGRAM">小程序</el-radio>
            <el-radio label="WEB">h5</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="小程序appid"
                      key="program_appid"
                      v-if="form.jump_type === 'PROGRAM'"
                      prop="program_appid"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入小程序appid"
                    v-model="form.program_appid"></el-input>
        </el-form-item>
        <el-form-item label="小程序路径"
                      key="program_url"
                      v-if="form.jump_type === 'PROGRAM'"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入小程序路径"
                    v-model="form.program_url"></el-input>
        </el-form-item>
        <el-form-item label="内部路径path"
                      key="WYH_URL"
                      v-if="form.jump_type === 'WYH'"
                      prop="program_url"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入小程序url"
                    v-model="form.program_url"></el-input>
        </el-form-item>
        <el-form-item label="h5url"
                      key="web_url"
                      v-if="form.jump_type === 'WEB'"
                      prop="web_url"
                      class="width300">
          <el-input type="text"
                    placeholder="请输入h5url"
                    v-model="form.web_url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submitForm('form')">{{form.id?'立即修改':'立即创建'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Editor from '@/components/Editor'
import ImgUpload from '@/components/ImgUpload'
import { setCardKey } from '@/api/market'
export default {
  name: '',
  data () {
    return {
      dateTime: [],
      imgList: [],
      imgList2: [],
      dialogVisible: false,
      form: {
        jump_type: 'NOT',
        name: '',
        platform_name: '',
        img_id: '',
        logo_img_id: '',
        desc: '',
        subtitle: '',
        started_at: '',
        ended_at: '',
        id: 0,
        program_url: '',
        program_appid: '',
        web_url: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入卡名称', trigger: 'blur' }
        ],
        logo_img_id: [
          { required: true, message: '请上传卡密logo', trigger: 'change' }
        ],
        img_id: [
          { required: true, message: '请上传卡密背景图', trigger: 'change' }
        ],
        platform_name: [
          { required: true, message: '请输入平台名称', trigger: 'blur' }
        ],
        subtitle: [
          { required: true, message: '请输入副标题', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ],
        program_appid: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ],
        program_url: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ],
        web_url: [
          { required: true, message: '请输入使用说明', trigger: 'blur' }
        ],
        started_at: [
          { required: true, message: '请选择活动日期', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    ImgUpload,
    Editor
  },
  methods: {
    dateChange (val) {
      this.form.started_at = val[0]
      this.form.ended_at = val[1]
    },
    showForm (row) {
      const form = Object.assign({}, row)
      this.dialogVisible = true
      this.$nextTick(() => {
        if (row) {
          form.img_id = form.img_data ? form.img_data.id : ''
          form.logo_img_id = form.logo_img_data ? form.logo_img_data.id : ''
          form.jump_type = form.jump_type || 'NOT'
          this.form = form
          this.dateTime = [form.started_at, form.ended_at]
          this.imgList2 = form.logo_img_data ? [form.logo_img_data] : []
          this.imgList = form.img_data ? [form.img_data] : []
        } else {
          this.form = {
            name: '',
            platform_name: '',
            img_id: '',
            desc: '',
            subtitle: '',
            started_at: '',
            ended_at: '',
            logo_img_id: '',
            id: 0,
            jump_type: 'NOT'
          }
          this.dateTime = []
          this.imgList = []
          this.imgList2 = []
        }
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const res = await setCardKey(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('on-success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
</style>
